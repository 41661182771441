window.htmx = require('htmx.org');
const { addInteractions } = require('./lib/interactions');
const { tooltip } = require('@uswds/uswds');
const { isMobileBrowser, resizeMainContent } = require('./lib/utils');
const { initializeHeader } = require('./lib/header');
const { initializeTabs } = require('./lib/tabs');
require('./lib/htmxing');

if (isMobileBrowser()) {
    document.body.classList.add('is-mobile');
}

const initializeMainContent = () => {
    window.addEventListener('resize', resizeMainContent);
    window.dispatchEvent(new Event('resize'));
}; // initializeMainContent

if (document.body.classList.contains('logged_in')) {
    addInteractions();

    // Profile-related things

    const { ProfileDetail } = require('./lib/profiles');
    if (document.body.classList.contains('entity-detail')) {
        window.ProfileDetail = ProfileDetail;
        window.addEventListener('load', ProfileDetail.init);
    }

    // Search-related things
    const { TandemSearch } = require('./lib/search');
    if (document.body.classList.contains('search')) {
        window.TandemSearch = TandemSearch;
        // window.onload = TandemSearch.init;
        window.addEventListener('load', TandemSearch.init);
    }
}

window.addEventListener('load', () => {
    initializeMainContent();
    initializeHeader();
    initializeTabs();
});
